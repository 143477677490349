import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getTackleSessionFromOtk } from './lib/salesforceCanvasSession';

const ONE_TIME_KEY_PARAM = 'otk';

/** Manages Salesforce Canvas session data and sets access_token in window.Sfdc */
export function useCanvasSession() {
  const urlParams = new URLSearchParams(window.location.search);
  const otk = urlParams.get(ONE_TIME_KEY_PARAM);

  const { data: canvasSession, isLoading } = useQuery({
    queryKey: [otk],
    queryFn: () => getTackleSessionFromOtk(otk),
    enabled: !!otk,
    staleTime: Infinity,
  });

  const accessToken = canvasSession?.access_token;
  useEffect(() => {
    if (!accessToken) return;
    // set token so it can be grabbed from window.Sfdc
    Sfdc.canvas.oauth.token(accessToken);
  }, [accessToken]);

  return {
    canvasSession,
    isLoadingCanvasSession: isLoading,
    /** the Salesforce Opportunity Id */
    crmId: canvasSession?.context?.record?.Id,
    /** the client object to pass into Sfdc to resize canvas */
    canvasClient: canvasSession?.context?.client,
  };
}
