import React, { useMemo } from 'react';
import { Tooltip, Box } from 'vendor/material';
import { ArrowUp, ArrowDown } from 'mdi-material-ui';
import useStyles from './SortHeader.styles';
import classNames from 'classnames';
import {
  defaultSortState,
  OpportunityColumnKey,
  SortState,
} from '../../../../helpers/urlParamsHelper';

interface SortHeaderProps {
  sortState: SortState;
  onSort: (newSortState: SortState) => void;
  columnKey: OpportunityColumnKey;
  // isSortDisabled: boolean;
}

const SortHeader: React.FC<SortHeaderProps> = ({
  children,
  columnKey,
  sortState,
  onSort,
  // isSortDisabled,
}) => {
  const { sortKey, orderAscending } = sortState || {};

  const classes = useStyles();
  // TODO: Disable sort depending on key
  // const isSortable = !isSortDisabled && columnKey !== OpportunityColumnKey.STATUS;
  // const shouldReAlignHeaderColumn =
  //   reAlignHeaderColumn.includes(columnKey) &&
  //   sortState?.sortKey !== defaultSortKey;
  const onHeaderClicked = () => {
    let newSortState = {};
    // if (!isSortable) {
    //   return;
    // }
    if (columnKey !== sortKey) {
      // First click on a new column
      newSortState = {
        sortKey: columnKey,
        orderAscending: false,
      };
    } else if (columnKey === sortKey && !orderAscending) {
      // Second click on the same column
      newSortState = {
        sortKey: columnKey,
        orderAscending: true,
      };
    } else if (columnKey === sortKey && orderAscending) {
      // Third click on the same column, reset the sort state to default
      newSortState = { ...defaultSortState };
    }
    onSort(newSortState as SortState);
  };

  // TODO: Create new component
  const sortArrow = useMemo(() => {
    const ArrowComponent = orderAscending ? ArrowUp : ArrowDown;
    const tooltipTitle = orderAscending
      ? 'Change sort to default'
      : 'Change sort direction to ascending';

    return (
      <Tooltip title={tooltipTitle}>
        <ArrowComponent fontSize="inherit" className={classes.arrow} />
      </Tooltip>
    );
  }, [orderAscending, classes.arrow]);

  return (
    <Box
      className={classNames(
        classes.sortHeaderContainer,
        // isSortable && classes.sortable,
        // shouldReAlignHeaderColumn && classes.marginLeftSort,
      )}
      onClick={onHeaderClicked}
    >
      {children}
      {columnKey === sortState?.sortKey && sortArrow}
    </Box>
  );
};

export default SortHeader;
