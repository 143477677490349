import { useOpportunity } from '../UnifiedOpportunityForm/providers/OpportunityProvider';
import AceOpportunityDetails from './AceOpportunityDetails/AceOpportunityDetails';
import { Loader } from '@tackle-io/platform-ui';

const UnifiedOpportunityDetails = () => {
  const { opportunity, isOpportunityLoading, opportunityError, isSRRP } =
    useOpportunity();

  if (isOpportunityLoading) {
    return <Loader />;
  }

  if (opportunityError) {
    console.log('opportunityError: ', opportunityError);
    return <div>Error loading opportunity</div>;
  }

  if (!opportunity) {
    return <div>No opportunity found</div>;
  }

  return <AceOpportunityDetails opportunity={opportunity} isSRRP={isSRRP} />;
};

export default UnifiedOpportunityDetails;
