import {
  SyncedIcon,
  SyncErrorIcon,
  SyncingIcon,
  WarningIcon,
} from 'packages/cosell/assets';
import { CoSellSyncStatus } from 'packages/cosell/src/components/CoSellSyncStatusTag/CoSellSyncStatusTag';
import { OptimizedTooltip } from 'packages/cosell/src/components/OptimizedTooltip/OptimizedTooltip';

export const SyncStatus = ({
  syncStatus,
}: {
  syncStatus: CoSellSyncStatus | null;
}) => {
  switch (syncStatus) {
    case CoSellSyncStatus.SYNCED:
      return (
        <OptimizedTooltip position="bottom" content="Synced">
          <span>
            <SyncedIcon />
          </span>
        </OptimizedTooltip>
      );
    case CoSellSyncStatus.SALES_STAGE_OUT_OF_SYNC:
      return (
        <OptimizedTooltip position="bottom" content="Sales stages out of sync">
          <span>
            <WarningIcon />
          </span>
        </OptimizedTooltip>
      );
    case CoSellSyncStatus.SYNCING:
      return (
        <OptimizedTooltip position="bottom" content="Syncing">
          <span>
            <SyncingIcon />
          </span>
        </OptimizedTooltip>
      );
    case CoSellSyncStatus.SYNC_ERROR:
      return (
        <OptimizedTooltip position="bottom" content="Sync error">
          <span>
            <SyncErrorIcon />
          </span>
        </OptimizedTooltip>
      );
    default:
      return null;
  }
};
