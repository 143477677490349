export enum coSellDetailsPageDataId {
  SYNC_STATUS_ICON = 'co_sell_sync_status',
  CANCEL_EDIT_MODAL = 'co_sell_cancel_edit_modal',
  SUBMIT_EDIT_MODAL = 'co_sell_submit_edit_modal',
  CREATE_BUTTON = 'co_sell_create_button',
}
export enum coSellLandingPageDataId {
  CREATE_BUTTON = 'co_sell_create_button',
  OPPORTUNITIES_TABLE_HEADER_ROW = 'co_sell_opportunities_table_header_row',
  OPPORTUNITIES_SEARCH = 'co_sell_opportunities_search',
}
