import coSellClient from 'packages/cosell/api/coSellClient';
import { AwsEnumListResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { solutionsApiToFormFormatter } from 'packages/cosell/src/utilities/formatSolutions';
import { createContext, useContext } from 'react';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { ErrorResponse } from 'packages/cosell/src/types';
import { useQuery } from '@tanstack/react-query';

interface OpportunityContextProps {
  vendorId: string;
  token: string;
  solutionList: Array<{ value: string; display: string }>;
  awsEnumListMap: AwsEnumListResponse;
  authorizedCosellClient: ReturnType<typeof coSellClient>;
  opportunityId: string;
  opportunity: AceOpportunityResponse;
  opportunityError: ErrorResponse;
  isSRRP: boolean;
  isOpportunityLoading?: boolean;
}

export const OpportunityContext = createContext<OpportunityContextProps>({
  vendorId: null,
  token: null,
  solutionList: null,
  opportunity: null,
  opportunityId: null,
  opportunityError: null,
  awsEnumListMap: null,
  authorizedCosellClient: null,
  isSRRP: null,
  isOpportunityLoading: false,
});

export interface OpportunityProviderProps {
  vendorId?: string;
  token?: string;
  opportunityId?: string;
  children?: React.ReactElement;
}

// TODO: move queryKeys to a queryKey dictionary
export const OpportunityProvider = ({
  vendorId,
  token,
  children,
  opportunityId,
}: OpportunityProviderProps) => {
  const authorizedCosellClient = coSellClient(token);

  // TODO: get isSRRP from getSettings API later
  const isSRRP = false;
  const { data: solutionList } = useQuery({
    queryFn: () => authorizedCosellClient.getVendorAceSolutions(),
    enabled: !!authorizedCosellClient,
    select: (data) => solutionsApiToFormFormatter(data?.solutions),
    staleTime: Infinity,
  });

  const { data: awsEnumListMap } = useQuery({
    queryKey: ['aws-cosell-enum-options'],
    queryFn: () => authorizedCosellClient.getAwsCoSellEnumOptions(),
    enabled: !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: Infinity,
  });

  const {
    data: opportunity,
    error: opportunityError,
    isLoading: isOpportunityLoading,
  } = useQuery({
    queryKey: [opportunityId, 'ace'],
    queryFn: () => authorizedCosellClient.getOpportunityByIdV3(opportunityId),
    enabled: !!opportunityId && !!authorizedCosellClient,
    // adjust this to a value that makes sense for this data
    staleTime: Infinity,
  });

  return (
    <OpportunityContext.Provider
      value={{
        vendorId,
        token,
        solutionList,
        awsEnumListMap,
        authorizedCosellClient,
        opportunityId,
        opportunity,
        // @ts-expect-error -- need to fix this
        opportunityError, // TODO: Add error handling,
        isSRRP,
        isOpportunityLoading,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error('useOpportunity must be used within a OpportunityProvider');
  }
  return context;
}
