import { Checkbox } from '@tackle-io/platform-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from 'vendor/material';
import { OpportunitiesTableColumnNames } from '../../utils';
import useStyles from './AceOpportunityRow.styles';
import { TableRowCell } from '../TableRowCell';
import classNames from 'classnames';
import type {
  ColumnWidths,
  AceOpportunity,
  RowCheckboxToggleEvent,
} from '../../types';
import { AwsCoSellStatusTag } from 'packages/cosell/src/components/AwsCoSellStatusTag';
import { AwsCoSellStageTag } from 'packages/cosell/src/components/AwsCoSellStageTag';
import { SyncStatus } from '../SyncStatus/SyncStatus';
import { getSyncStatus } from 'packages/cosell/src/components/CoSellSyncStatusTag/CoSellSyncStatusTag';
import { OfficeBuilding } from 'mdi-material-ui';
import { Slide } from 'react-toastify';
import ToolTipCopy from 'packages/cosell/src/components/ToolTipCopy/ToolTipCopy';
import { formatDate } from 'packages/cosell/src/utilities/formatDate';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';

interface AceOpportunityRowProps {
  opportunity: AceOpportunity;
  columnWidths: ColumnWidths;
  dataId?: string;
  handleRowSelect: RowCheckboxToggleEvent;
  isSelected: boolean;
  style?: React.CSSProperties;
}

export const toastOptions = {
  type: 'info',
  closeButton: false,
  position: 'top-center',
  autoClose: 2000,
  closeOnClick: false,
  transition: Slide,
  draggable: false,
};

export const AceOpportunityRow: React.FC<AceOpportunityRowProps> = ({
  handleRowSelect,
  opportunity,
  columnWidths,
  dataId,
  isSelected,
  style,
}) => {
  const opportunityId = opportunity.id;
  const classes = useStyles();
  const history = useHistory();

  const handleRowClick = () => {
    history.push(`${COSELL_PATH}/opportunity/aws/${opportunity.id}`);
  };

  const toggleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const opportunityKey = opportunityId;
    handleRowSelect(event, opportunityKey);
  };

  if (columnWidths === undefined) return null;
  return (
    <div
      className={classNames(classes.tableRow)}
      role="row"
      aria-label={`Opportunity ID ${opportunityId}`}
      data-id={dataId}
      onClick={handleRowClick}
      style={style}
    >
      <TableRowCell
        key={`${opportunityId}--checkbox`}
        columnName={OpportunitiesTableColumnNames.CHECKBOX}
        columnWidths={columnWidths}
      >
        <Checkbox
          onClick={(event) => event.stopPropagation()}
          onChange={toggleSelect}
          checked={isSelected}
        />
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--disbursement_date`}
        columnName={OpportunitiesTableColumnNames.PROJECT_NAME}
        columnWidths={columnWidths}
      >
        <>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <OfficeBuilding />
              <Typography variant="body1">
                <ToolTipCopy
                  content={opportunity.title}
                  name="Project name"
                  hideNameInTooltip={true}
                  hideName={true}
                  maxWidth={310}
                />
              </Typography>
            </div>
          </Grid>
          <Grid className={classNames(classes['mt-0.5'], classes.flex)}>
            <span
              className={classNames(classes.flex, classes.smallLabel)}
              style={{ marginRight: '8px' }}
            >
              <ToolTipCopy
                content={opportunity.cloudProviderId}
                name="Cloud ID"
                hideNameInTooltip={true}
              />
            </span>
            {opportunity.crmId && (
              <span className={classNames(classes.flex, classes.smallLabel)}>
                <ToolTipCopy
                  content={opportunity.crmId}
                  name="CRM ID"
                  hideNameInTooltip={true}
                />
              </span>
            )}
          </Grid>
        </>
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--customer`}
        columnName={OpportunitiesTableColumnNames.CUSTOMER}
        columnWidths={columnWidths}
      >
        <>
          <ToolTipCopy
            content={opportunity.companyName}
            name="Customer name"
            hideNameInTooltip={true}
            hideName={true}
          />
        </>
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--cosell_status`}
        columnName={OpportunitiesTableColumnNames.COSELL_STATUS}
        columnWidths={columnWidths}
      >
        <AwsCoSellStatusTag status={opportunity.cloudStatus} />
      </TableRowCell>

      <TableRowCell
        key={`${opportunityId}--cosell_stage`}
        columnName={OpportunitiesTableColumnNames.COSELL_STAGE}
        columnWidths={columnWidths}
      >
        <AwsCoSellStageTag stage={opportunity.cloudSalesStage} />
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--date_created`}
        columnName={OpportunitiesTableColumnNames.DATE_CREATED}
        columnWidths={columnWidths}
      >
        <>
          <Grid item>
            <Typography variant="body1">
              {formatDate(opportunity.created)}
            </Typography>
          </Grid>
          <Grid className={classes['mt-0.25']}>
            <span className={classes.smallLabel}>
              Updated {formatDate(opportunity.lastModified)}
            </span>
          </Grid>
        </>
      </TableRowCell>
      <TableRowCell
        key={`${opportunityId}--sync_status`}
        columnName={OpportunitiesTableColumnNames.SYNC_STATUS}
        columnWidths={columnWidths}
      >
        <SyncStatus syncStatus={getSyncStatus(opportunity.pendingRequestIds)} />
      </TableRowCell>
    </div>
  );
};

export default AceOpportunityRow;
