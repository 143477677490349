import { cosellHttpClient } from '../cosellHttpClient';

/**
 * TODO: update enum/union types for cloudStatus, cloudSalesStage
 * and any other fields it applies to
 */
type TackleOpportunityListResponse = {
  opportunities:
    | null
    | {
        id: string;
        cloudProviderId: string;
        cloudStatus: string;
        cloudSalesStage: string;
        companyName: string;
        created: string;
        pendingRequestIds: string[];
        title: string;
        opportunityType: string;
        crmId: string;
      }[];
  total: number;
  currentPageCount: number;
};

export const getTackleOpportunityListByCrmId = async ({
  crmId,
}: {
  crmId: string;
}): Promise<TackleOpportunityListResponse> => {
  return cosellHttpClient.get(`api/opportunities?crmId=${crmId}`).json();
};
