import mockTestOppDetails from './mockTestOppDetails.json';
import { set } from 'lodash-es';
// Mock helpers to get the variants to conditionally render fields
// Not to be confused with disabled fields which is handled separate with isFieldEditable

const setAttributes = ({ attributes, oppDetails }) => {
  const newOppDetails = { ...oppDetails };
  Object.entries(attributes).forEach(([key, value]) =>
    set(newOppDetails, key, value),
  );
  return newOppDetails;
};

export const getOppDraft = () => {
  const attributes = {
    'lifeCycle.reviewStatus': 'Pending Submission',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppActionRequired = () => {
  const attributes = {
    'lifeCycle.reviewStatus': 'Action Required',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppApproved = () => {
  const attributes = {
    'lifeCycle.reviewStatus': 'Approved',
    'lifeCycle.stage': 'Prospect',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppLaunched = () => {
  const attributes = {
    'lifeCycle.reviewStatus': 'Approved',
    'lifeCycle.stage': 'Launched',
    softwareRevenue: {
      deliveryModel: 'Contract',
    },
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getSrrpOppLaunched = () => {
  const attributes = {
    'lifeCycle.reviewStatus': 'Approved',
    'lifeCycle.stage': 'Launched',
    softwareRevenue: {
      deliveryModel: 'Contract',
      effectiveDate: '2024-08-19T15:30:20Z',
      expirationDate: '2024-08-19T15:30:20Z',
      value: { currencyCode: 'USD', amount: 98765 },
    },
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppNationalSecurityYes = () => {
  const attributes = {
    'accessControls.nationalSecurity': 'Yes',
    'customer.account.address.countryCode': 'US',
    'customer.account.industry': 'Government',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppCompetitiveTrackingOther = () => {
  const attributes = {
    'project.competitorName': '*Other',
    'project.otherCompetitorNames': 'Other competitor',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppMarketingFundsNo = () => {
  const attributes = {
    'marketing.awsFundingUsed': 'No',
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getOppOpportunitySourceNone = () => {
  const attributes = {
    'marketing.source': 'None',
    'marketing.campaignName': null,
    'marketing.channels': null,
    'marketing.useCases': null,
    'marketing.awsFundUsed': null,
  };

  const newOppDetails = setAttributes({
    attributes,
    oppDetails: mockTestOppDetails,
  });

  return newOppDetails;
};

export const getSolutionList = () => {
  return {
    solutions: [
      {
        id: 'tackle-solution-2mZtKwRpZz7Z89Fvbq5XE6teObp',
        cloudProviderId: 'S-0014870',
        name: 'Tackle Platform',
        status: 'Active',
        category: 'Software Product',
        created: '2024-09-25T20:24:25Z',
        lastSynced: '2024-09-25T20:24:25Z',
      },
      {
        id: 'tackle-solution-2mZtKykqFE7RqcBGT0KzE1ARjnr',
        cloudProviderId: 'S-0050051',
        name: 'Tackle AI',
        status: 'Active',
        category: 'Software Product',
        created: '2024-09-25T20:24:25Z',
        lastSynced: '2024-09-25T20:24:25Z',
      },
      {
        id: 'tackle-solution-2mZtKw10oMNUgY56FzozYsarFVh',
        cloudProviderId: 'S-0050052',
        name: 'Tackle Cloud Sync',
        status: 'Active',
        category: 'Software Product',
        created: '2024-09-25T20:24:25Z',
        lastSynced: '2024-09-25T20:24:25Z',
      },
    ],
    total: 3,
    currentPageCount: 3,
  };
};

export const getMockInitialValues = () => {
  const mockInitialValues = {
    duns: '123456789',
    additionalComments: 'test additional comments',
    address: '3423 st',
    apnPrograms: ['APN Immersion Days'],
    awsAccountId: '123423523221',
    city: 'Dallas',
    state: 'Texas',
    competitiveTracking: 'On-Prem',
    countryCode: 'US',
    customerBusinessProblem: 'mock business problem',
    customerCompanyName: 'Li LLC',
    customerUseCase: 'Archiving',
    customerWebsite: 'www.li.com',
    deliveryModels: ['SaaS or PaaS'],
    expectedMonthlyAwsRevenue: 20000,
    industry: 'Aerospace',
    industryOther: null,
    isMarketingDevelopmentFunded: null,
    marketingSource: 'None',
    marketingActivityChannel: null,
    marketingActivityUseCases: null,
    nationalSecurity: null,
    nextStep: 'test next step',
    opportunityType: 'Net New Business',
    otherCompetitorNames: null,
    otherSolutionDescription: null,
    parentOppId: null,
    partnerNeedType: 'Co-Sell with AWS',
    postalCode: '75000',
    primaryContactEmail: 'pp@email.com',
    primaryContactFirstName: 'Pat',
    primaryContactLastName: 'Partner',
    primaryContactPhone: '1242243333',
    primaryContactTitle: 'PartnerAccountManager',
    primaryNeedsFromAws: ['Co-Sell - Business Presentation'],
    projectTitle: 'test project title',
    salesActivities: ['Customer has shown interest in solution'],
    solutions: ['S-0014870'],
    targetCloseDate: '2026-09-03T07:00:00.000Z',
  };
  return mockInitialValues;
};
