import React from 'react';
import { Box, Skeleton } from 'vendor/material';
import useStyles from './OpportunityLoadingRows.styles';
import { PAGE_LIMIT } from '../../../../helpers/urlParamsHelper';

export const OpportunityLoadingRows: React.FC = (): JSX.Element => {
  const classes = useStyles();

  const rows = [...Array(PAGE_LIMIT)].map((_, idx) => (
    <Box my={2} key={idx}>
      <Skeleton height={50} width="100%" className={classes.loadingRow} />
    </Box>
  ));
  return <>{rows}</>;
};
