import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
import { cosellHttpClient } from '../cosellHttpClient';

export const getTackleCoSellOpportunity = async ({
  id,
}: {
  id: string;
}): Promise<AceOpportunityResponse> => {
  return cosellHttpClient.get(`api/opportunities/${id}`).json();
};
