import { Location } from 'history';

export const SEARCH_KEY = 'q';
export const SORT_KEY = 'sort';
export const PAGE_KEY = 'page';
export const PAGE_SIZE_KEY = 'pageSize';

export const PAGE_LIMIT = 10;

export enum OpportunityColumnKey {
  PROJECT_NAME = 'project_name',
  CUSTOMER = 'customer',
  COSELL_STATUS = 'cosell_status',
  COSELL_STAGE = 'cosell_stage',
  DATE_CREATED = 'date_created',
  SYNC_STATUS = 'sync_status',
}

export interface SortState {
  sortKey: OpportunityColumnKey;
  orderAscending: boolean;
}

export const defaultSortKey = OpportunityColumnKey.DATE_CREATED;

export const defaultSortState: SortState = {
  sortKey: defaultSortKey,
  orderAscending: false,
};

export enum Operation {
  FILTER = 'FILTER',
  SORT = 'SORT',
  SEARCH = 'SEARCH',
}

interface Filter {
  value: string | boolean;
  operation: Operation;
  key?: string | OpportunityColumnKey;
  type?: string;
  label?: string;
}

/**
 * Helper function to get the existing URL params and update the search param
 * Then returns the new URL path
 */
export const getUrlParamsPath = ({
  filter,
  location,
}: {
  filter: Filter;
  location: Location;
}): string => {
  const searchParams = new URLSearchParams(location.search);
  //   TODO: Filter operations
  switch (filter.operation) {
    case Operation.SEARCH:
      searchParams.delete(SEARCH_KEY);
      if (filter?.value) {
        searchParams.append(SEARCH_KEY, encodeURIComponent(filter.value));
        searchParams.delete(PAGE_KEY);
      }
      break;
    case Operation.SORT:
      // Reset sort to default
      searchParams.delete(SORT_KEY);

      break;

    case Operation.FILTER:
      break;
  }

  return `${location.pathname}?${searchParams.toString()}`;
};
