import ky from 'ky';
import { get } from 'lodash';
import { reportError } from 'utils/monitor';
import { ErrorResponse } from '../src/types';
import {
  CreateAceOpportunityRequest,
  UpdateAceOpportunityRequest,
} from '../src/types/requests/AceOpportunityRequest';
import { AwsEnumListResponse } from '../src/types/responses/AwsEnumResponse';
import {
  AceOpportunityCampaignNameEnum,
  AwsProductsEnum,
} from '../src/types/enums';
// To mock details page or edit page for testing
// import {
//   getOppLaunched,
//   getSrrpOppLaunched,
//   getOppDraft,
//   getOppActionRequired,
//   getOppApproved,
//   getOppNationalSecurityYes,
//   getOppCompetitiveTrackingOther,
//   getOppMarketingFundsNo,
//   getOppOpportunitySourceNone,
// } from './mockHelpers';
import { AceOpportunityResponse } from '../src/types/responses/AceOpportunityResponse';
import { TackleOperationId } from './utils';
import { AceSolutionListResponse } from '../src/types/responses/AceSolutionResponse';
import { AceOpportunitiesListResponse } from '../src/types/responses/AceOpportunitiesListResponse';
// import { getSolutionList } from './mockHelpers';

// our calls to create/update ACE opportunities are rather expensive
// unfortunately, the speed at which ACE processes the opportunities we send it is out of our control
// our API service waits up to 20 seconds for ACE to process our opportunities, we need to provide enough time for that to complete
const COSELL_API_TIMEOUT = 25000;
const TIMEOUT_STATUS_CODE = 408;

const getErrorResponse = async (error) => {
  let errorResponse: ErrorResponse = {
    httpStatusCode: get(error, 'response.status', 500),
    message: get(error, 'message', 'An error was encountered'),
    detail: get(error, 'message', 'An error was encountered'),
  };
  if (error instanceof ky.HTTPError) {
    const errorJson = await error.response.json();
    errorResponse.detail = errorJson.detail;
  } else if (error instanceof ky.TimeoutError) {
    errorResponse.httpStatusCode = TIMEOUT_STATUS_CODE;
  }
  return errorResponse;
};

const makeCosellApiClient = (token) => {
  if (!token) {
    return null;
  }
  const cosellApiConfig = {
    prefixUrl: process.env.REACT_APP_TACKLE_CO_SELL_V3_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    hooks: {
      beforeRequest: [
        async (request) => {
          request.headers.set('Authorization', `Bearer ${token}`);
          return request;
        },
      ],
    },
  };
  const cosellHttpClient = ky.create(cosellApiConfig);

  const coSellClient = {
    async createOpportunity(
      requestBody: CreateAceOpportunityRequest,
      tackleOperationId: TackleOperationId,
    ): Promise<{ id: string }> {
      try {
        const result = await cosellHttpClient.post(`api/opportunities`, {
          headers: {
            'tackle-operation-id': tackleOperationId,
          },
          body: JSON.stringify(requestBody),
          timeout: COSELL_API_TIMEOUT,
        });
        return result.json();
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        throw new Error(`An error was encountered: ${error?.message}`, {
          cause: err,
        });
      }
    },

    async updateOpportunity(
      body: UpdateAceOpportunityRequest,
      opportunityId: string,
      tackleOperationId: TackleOperationId,
    ): Promise<{ id: string }> {
      try {
        const result = await cosellHttpClient.post(
          `api/opportunities/${opportunityId}`,
          {
            headers: {
              'tackle-operation-id': tackleOperationId,
            },
            body: JSON.stringify(body),
            timeout: COSELL_API_TIMEOUT,
          },
        );

        // 202 Accepted response indicates that the request is going to be queued and sent to ACE
        if (result.status === 202) {
          // Success case
          return {
            id: opportunityId,
          };
        }
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        throw new Error(`An error was encountered: ${error?.message}`, {
          cause: err,
        });
      }
    },
    async acceptOrRejectOpportunity({
      opportunityId,
      tackleOperationId,
    }: {
      opportunityId: string;
      tackleOperationId: string;
    }): Promise<AceOpportunityResponse | ErrorResponse> {
      try {
        const result = await cosellHttpClient.post(
          `api/opportunities/${opportunityId}`,
          {
            headers: {
              'tackle-operation-id': tackleOperationId,
            },
            body: '{}',
            timeout: COSELL_API_TIMEOUT,
          },
        );
        return result.json();
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        throw new Error(`An error was encountered: ${error?.message}`, {
          cause: err,
        });
      }
    },
    async getAwsCoSellEnumOptions(): Promise<AwsEnumListResponse> {
      const result = await cosellHttpClient
        .get(`api/settings/picklist-options`, {
          timeout: COSELL_API_TIMEOUT,
        })
        .json();

      // temporarily append hard-coded values to this response until the API returns them
      result['awsProducts'] = AwsProductsEnum;
      result['campaignName'] = AceOpportunityCampaignNameEnum;
      result['marketingActivityUseCase'] = [];

      return result as AwsEnumListResponse;
    },

    async getOpportunityByIdV3(
      opportunityId: string,
    ): Promise<AceOpportunityResponse> {
      try {
        const response = await cosellHttpClient.get(
          `api/opportunities/${opportunityId}`,
        );
        const jsonResponse = await response.json();
        return jsonResponse;
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        throw err;
      }
    },
    async getVendorAceSolutions(): Promise<AceSolutionListResponse> {
      // Keep this here, you won't get any response from API if you point to a branch in co-sell-aws instead of main
      // getSolutionList()

      try {
        const result = await cosellHttpClient.get(`api/solutions`).json();
        // @ts-expect-error - Type '{}' is missing the following properties
        // from type 'AceSolutionListResponse': solutions, total, currentPageCount
        return result;
      } catch (error) {
        if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
          const err = await getErrorResponse(error);
          throw new Error('An error was encountered', { cause: err });
        }
      }
    },
    async getOpportunitiesList({
      q,
    }: {
      q?: string;
    }): Promise<AceOpportunitiesListResponse> {
      try {
        // TODO: Append other filters onto URL
        const url = `api/opportunities${q ? `?q=${q}` : ''}`;
        const result = await cosellHttpClient.get(url);

        return result.json();
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        throw err;
      }
    },
  };

  return coSellClient;
};

const coSellClient = (token) => makeCosellApiClient(token);
export default coSellClient;
