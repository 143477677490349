import { Checkbox } from '@tackle-io/platform-ui';
import { useCallback, useRef, useState } from 'react';
import { Box, Typography } from 'vendor/material';
import useStyles from './CoSellOpportunitiesTable.styles';
import type {
  ColumnWidths,
  HeaderColumn,
  AceOpportunity,
  RowCheckboxToggleEvent,
} from './types';
import {
  OpportunitiesTableColumnNames,
  defaultOpportunityColumnsObj,
} from './utils';
import { GeneralEmptyStateIcon } from 'packages/cosell/assets/GeneralEmptyStateIcon';
import { coSellLandingPageDataId } from 'packages/cosell/src/utilities/intercomEnums';
import useGetOpportunitiesList from 'packages/cosell/src/hooks/useGetOpportunitiesList';
import {
  AceOpportunityRow,
  OpportunityLoadingRows,
  OpportunitiesTableError,
  TableHeader,
} from './components';

export const renderAceOpportunityRows = ({
  opportunityRows,
  columnWidths,
  handleSelectOpportunity,
  allSelected,
  selectedOpportunities,
}: {
  opportunityRows: AceOpportunity[];
  columnWidths: ColumnWidths;
  handleSelectOpportunity: RowCheckboxToggleEvent;
  allSelected: boolean;
  selectedOpportunities: Set<string>;
}) =>
  opportunityRows?.map((opportunity, rowNumber) => {
    const opportunityKey = opportunity.id;
    return (
      <AceOpportunityRow
        key={`${opportunityKey}_${rowNumber}__opportunity_id`}
        opportunity={opportunity}
        columnWidths={columnWidths}
        handleRowSelect={handleSelectOpportunity}
        isSelected={allSelected || selectedOpportunities[opportunityKey]}
        dataId={
          rowNumber === 0
            ? coSellLandingPageDataId.OPPORTUNITIES_TABLE_HEADER_ROW
            : undefined
        }
      />
    );
  });

export const CoSellOpportunitiesTable = () => {
  const classes = useStyles();
  // TODO: Update column widths so that there's a horizontal scrollbar on smaller than 1024px screen
  const [columnWidths, setColumnWidths] = useState({
    ...defaultOpportunityColumnsObj,
  });

  const {
    opportunitiesList,
    opportunitiesListError,
    isOpportunitiesListLoading,
  } = useGetOpportunitiesList();

  const opportunities = opportunitiesList?.opportunities || [];

  const [selectedOpportunities, setSelectedOpportunities] = useState<
    Set<string>
  >(new Set());
  const tableHeaderContainerEl = useRef<HTMLDivElement>(null);

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const handleSelectAll = (
    changeEvent: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = changeEvent?.target?.checked;
    setAllSelected(isChecked);
    if (!isChecked) {
      setSelectedOpportunities(new Set());
    }
  };

  const AceOpportunityHeaders: HeaderColumn[] = [
    {
      field: OpportunitiesTableColumnNames.CHECKBOX,
      headerName: <Checkbox checked={allSelected} onChange={handleSelectAll} />,
    },
    {
      field: OpportunitiesTableColumnNames.PROJECT_NAME,
      headerName: 'Project name',
    },
    {
      field: OpportunitiesTableColumnNames.CUSTOMER,
      headerName: 'Customer',
    },
    {
      field: OpportunitiesTableColumnNames.COSELL_STATUS,
      headerName: 'Co-Sell status',
    },
    {
      field: OpportunitiesTableColumnNames.COSELL_STAGE,
      headerName: 'Co-Sell stage',
    },
    {
      field: OpportunitiesTableColumnNames.DATE_CREATED,
      headerName: 'Date created',
    },
    {
      field: OpportunitiesTableColumnNames.SYNC_STATUS,
      headerName: 'Sync status',
    },
  ];

  const opportunityRows = opportunities;

  const emptyResults = opportunityRows.length < 1;

  const handleSelectOpportunity: RowCheckboxToggleEvent = useCallback(
    (rowEvent, opportunityKey) => {
      if (rowEvent.target.checked === false && allSelected) {
        setAllSelected(false);
        setSelectedOpportunities(new Set());
      } else {
        setSelectedOpportunities((currentState) => ({
          ...currentState,
          [opportunityKey]: rowEvent.target.checked,
        }));
      }
    },
    [allSelected],
  );

  if (opportunitiesListError) return <OpportunitiesTableError />;

  const isEmptyTable = !isOpportunitiesListLoading && emptyResults;

  const isLoading = isOpportunitiesListLoading;

  const emptyTableContent = (
    <Box className={classes.emptyTable}>
      <GeneralEmptyStateIcon className={classes.emptyIcon} />
      <Typography className={classes.emptyHeader}>No results found</Typography>
      <Typography component={'p'} className={classes.emptyDescription}>
        Try changing your search criteria to find what you're looking for.
      </Typography>
    </Box>
  );

  return (
    <>
      <div className={classes.opportunitiesTableContainer} role="grid">
        <div ref={tableHeaderContainerEl} />
        <TableHeader
          tableColumns={AceOpportunityHeaders}
          setColumnWidths={setColumnWidths}
        />
        {isLoading ? (
          <OpportunityLoadingRows />
        ) : (
          <>
            {isEmptyTable
              ? emptyTableContent
              : renderAceOpportunityRows({
                  opportunityRows,
                  columnWidths,
                  handleSelectOpportunity,
                  allSelected,
                  selectedOpportunities,
                })}
          </>
        )}
      </div>
    </>
  );
};
