import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
import { SoftwareRevenue } from 'packages/cosell/src/types/responses/AceOpportunityResponse';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceAdditionalDetailsProps {
  isSRRPAndLaunched?: boolean;
  awsAccountId?: string;
  additionalComments?: string;
  partnerOpportunityIdentifier?: string;
  competitiveTracking?: string;
  softwareRevenue?: SoftwareRevenue;
}

const AceAdditionalDetails: React.FC<AceAdditionalDetailsProps> = ({
  isSRRPAndLaunched,
  awsAccountId,
  additionalComments,
  partnerOpportunityIdentifier,
  competitiveTracking,
  softwareRevenue,
}) => {
  return (
    <DetailsSectionCard title="Additional details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner CRM unique identifier"
          value={partnerOpportunityIdentifier}
        />

        <InfoItem
          itemTitle="Competitive tracking"
          value={competitiveTracking}
        />

        <InfoItem itemTitle="Additional comments" value={additionalComments} />
        <InfoItem itemTitle="AWS account ID" value={awsAccountId} />

        {/* TODO: add logic to render the follow fields only when the vendor is SRRP */}
        {isSRRPAndLaunched && (
          <>
            <InfoItem
              itemTitle="Marketplace offer ID"
              value={'Marketplace offer id?'} // TODO: add the correct value
            />
            <InfoItem
              itemTitle="Procurement type"
              value={'No Procurement type data'} // TODO: add the correct value
            />

            <InfoItem
              itemTitle="Customer software value"
              value={softwareRevenue?.value?.amount}
            />
            <InfoItem
              itemTitle="Currency"
              value={softwareRevenue?.value?.currencyCode}
            />

            <InfoItem
              itemTitle="Contract start date"
              value={softwareRevenue?.effectiveDate}
            />
            <InfoItem
              itemTitle="Contract end date"
              value={softwareRevenue?.expirationDate}
            />
          </>
        )}
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceAdditionalDetails;
