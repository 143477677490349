import {
  CanvasBulkCreateCosell,
  CanvasCreateCoSell,
  CanvasCoSellDetails,
} from '../cosell/src';
import { Loader } from '@tackle-io/platform-ui';
import { useCanvasSession } from './useCanvasSession';
import { useCanvasAppStyles } from './CanvasApp.styles';
import { AwsOpportunityActionsProvider } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/AwsOpportunityActionsProvider';
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider';

const CanvasApp = () => {
  const { canvasSession, isLoadingCanvasSession } = useCanvasSession();
  const classes = useCanvasAppStyles();

  if (isLoadingCanvasSession) {
    return <Loader />;
  }

  if (!canvasSession?.context?.action) {
    return <div>No context provided.</div>;
  }

  const getComponentToRender = () => {
    switch (canvasSession.context.action) {
      case 'CoSellDetails':
        return <CanvasCoSellDetails />;
      case 'CreateNewCoSellForm':
        return <CanvasCreateCoSell />;
      case 'BulkCreateCoSell':
        return <CanvasBulkCreateCosell />;
      default:
        return (
          <div>Unknown context: {canvasSession.context.action} provided.</div>
        );
    }
  };

  return (
    <AwsOpportunityActionsProvider token={canvasSession.access_token}>
      <div className={classes.canvasAppContainer}>{getComponentToRender()}</div>
    </AwsOpportunityActionsProvider>
  );
};

const CanvasAppWithSessionProvider = () => {
  return (
    <ReactQueryClientProvider>
      <CanvasApp />
    </ReactQueryClientProvider>
  );
};

export default CanvasAppWithSessionProvider;
