import { EditModalType } from '../src/components';
import { AceOpportunityReviewStatusEnum } from '../src/types/enums';

export enum TackleOperationId {
  CREATE_DRAFT_OPPORTUNITY = 'createDraftOpportunity',
  CREATE_OPPORTUNITY = 'createOpportunity',
  UPDATE_OPPORTUNITY = 'updateOpportunity',
  SUBMIT_OPPORTUNITY = 'submitOpportunity', //submit existing draft opportunity to ACE
  UPDATE_SUBMIT_OPPORTUNITY = 'updateSubmitOpportunity', // update the existing draft opportunity and submit to ACE
  CLOSE_LOST_OPPORTUNITY = 'closeLostOpportunity',
  LAUNCH_OPPORTUNITY = 'launchOpportunity',
  // Accept/Reject opportunity are for Mock API only
  ACCEPT_OPPORTUNITY = 'acceptOpportunity',
  REJECT_OPPORTUNITY = 'rejectOpportunity',
}

export const getEditModalTackleOperationId = (
  editModalType: EditModalType,
): TackleOperationId => {
  switch (editModalType) {
    case EditModalType.EDIT_LAUNCHED:
      return TackleOperationId.UPDATE_OPPORTUNITY;
    case EditModalType.LAUNCH:
      return TackleOperationId.LAUNCH_OPPORTUNITY;
    case EditModalType.CLOSE_LOST:
      return TackleOperationId.CLOSE_LOST_OPPORTUNITY;
  }
};

export const getEditPageSubmitTackleOperationId = (
  currentStatus: AceOpportunityReviewStatusEnum,
): TackleOperationId => {
  // the SUBMIT_OPPORTUNITY operation will only submit the existing draft opportunity to ACE,
  // if we want the user to be able to submit the draft with updates, the operation should be updateSubmitOpportunity
  if (currentStatus === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
    return TackleOperationId.UPDATE_SUBMIT_OPPORTUNITY;
  }
  return TackleOperationId.UPDATE_OPPORTUNITY;
};
