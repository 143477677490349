import { UpdateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import { UpdateAceOpportunityRequest } from '../../types/requests/AceOpportunityRequest';
import { SoftwareRevenue } from '../../types/responses/AceOpportunityResponse';
import {
  getAccessControl,
  getCustomer,
  getLifecycle,
  getMarketing,
  getPartnerOpportunityTeam,
  getProject,
} from './convertAceFormValuesToCreateRequest';

// TODO: Assign values as UpdateAceSRRPOpportunityFormValues & enforce types
export const getSoftwareRevenue = (values) => {
  const softwareRevenue: SoftwareRevenue = {
    deliveryModel: values?.deliveryModel,
    value: {
      amount: values?.customerSoftwareValue,
      currencyCode: values?.currencyCode,
    },
    effectiveDate: values?.effectiveDate,
    expirationDate: values?.expirationDate,
  };
  return softwareRevenue;
};

const getAwsMarketplaceOffers = (values: UpdateAceOpportunityFormValues) => {
  return values?.marketplaceOfferId ? [values.marketplaceOfferId] : null;
};

export const convertAceFormValuesToUpdateRequest = (
  values: UpdateAceOpportunityFormValues,
): UpdateAceOpportunityRequest => {
  return {
    accessControls: getAccessControl(values),
    customer: getCustomer(values),
    lifeCycle: getLifecycle(values),
    marketing: getMarketing(values),
    opportunityType: values.opportunityType,
    partnerOpportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
    awsMarketplaceOffers: getAwsMarketplaceOffers(values),
  };
};
