export enum OpportunitiesTableColumnNames {
  CHECKBOX = 'CHECKBOX',
  PROJECT_NAME = 'PROJECT_NAME',
  CUSTOMER = 'CUSTOMER',
  COSELL_STATUS = 'COSELL_STATUS',
  COSELL_STAGE = 'COSELL_STAGE',
  DATE_CREATED = 'DATE_CREATED',
  SYNC_STATUS = 'SYNC_STATUS',
}

export const defaultOpportunityColumnsObj = {
  CHECKBOX: null,
  PROJECT_NAME: null,
  CUSTOMER: null,
  COSELL_STATUS: null,
  COSELL_STAGE: null,
  DATE_CREATED: null,
  SYNC_STATUS: null,
};
