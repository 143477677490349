import {
  fontWeightMediumLarge,
  fontWeightRegular,
} from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  sectionBox: {
    padding: theme.spacing(1, 0),
    '& > *:first-child': {
      paddingTop: 0,
    },
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0),
    boxSizing: 'border-box',
  },
  titleText: {
    color: theme.palette.NEUTRAL500,
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(14),
  },
  subTitleBox: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1.5),
    boxSizing: 'border-box',
  },
  subTitleText: {
    color: theme.palette.NEUTRAL700,
    fontWeight: fontWeightMediumLarge,
    fontSize: theme.typography.pxToRem(12),
  },
  bodyText: {
    color: theme.palette.NEUTRAL700,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    '& > *:first-child': {
      paddingTop: 0,
    },
  },
}));

export default useStyles;
