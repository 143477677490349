import { CreateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  LifeCycle,
  Marketing,
  CreateCustomer,
  Project,
  OpportunityRequestAccount,
  CreateAceOpportunityRequest,
  AccessControls,
} from '../../types/requests/AceOpportunityRequest';

export const getAccount = (values: CreateAceOpportunityFormValues) => {
  const account: OpportunityRequestAccount = {
    address: getAddress(values),
    duns: values.duns,
    awsAccountId: values.awsAccountId,
    companyName: values.customerCompanyName,
    industry: values.industry,
    otherIndustry: values.industryOther,
    websiteUrl: values.customerWebsite,
  };
  return account;
};

export const getContact = (values: CreateAceOpportunityFormValues) => {
  const contact = {
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    email: values.customerEmail,
    phone: values.customerPhone ? `+${values.customerPhone}` : null,
    businessTitle: values.customerTitle,
  };
  return contact;
};

export const getAccessControl = (
  values: CreateAceOpportunityFormValues,
): AccessControls => {
  const accessControls: AccessControls = {
    nationalSecurity: values.nationalSecurity,
  };

  return accessControls;
};

export const getCustomer = (
  values: CreateAceOpportunityFormValues,
): CreateCustomer => {
  const customer: CreateCustomer = {
    account: getAccount(values),
    contact: getContact(values),
  };

  return customer;
};

export const getLifecycle = (values: CreateAceOpportunityFormValues) => {
  const targetCloseDate = values.targetCloseDate
    ? new Date(values.targetCloseDate)?.toISOString()
    : null;
  const lifeCycle: LifeCycle = {
    nextSteps: values.nextStep,
    targetCloseDate,
  };
  return lifeCycle;
};

export const getMarketing = (values: CreateAceOpportunityFormValues) => {
  const marketing: Marketing = {
    awsFundingUsed: values.isMarketingDevelopmentFunded,
    campaignName: values.campaignName,
    channels: values.marketingActivityChannel,
    source: values.marketingSource,
    useCases: values.marketingActivityUseCases,
  };
  return marketing;
};

export const getProject = (values: CreateAceOpportunityFormValues) => {
  const project: Project = {
    additionalComments: values.additionalComments,
    apnPrograms: values.apnPrograms,
    competitorName: values.competitiveTracking,
    customerBusinessProblem: values.customerBusinessProblem,
    customerUseCase: values.customerUseCase,
    deliveryModels: values.deliveryModels,
    expectedMonthlyAwsRevenue: {
      amount: values.expectedMonthlyAwsRevenue,
      currencyCode: values.expectedMonthlyAwsRevenue ? 'USD' : null,
    },
    otherCompetitorNames: values.otherCompetitorNames,
    salesActivities: values.salesActivities,
    title: values.projectTitle,
    otherSolutionDescription: values.otherSolutionDescription,
  };
  return project;
};

// For Create, we initialize with just 1
export const getPartnerOpportunityTeam = (
  values: CreateAceOpportunityFormValues,
) => {
  const contactFormFields = [
    'primaryContactFirstName',
    'primaryContactLastName',
    'primaryContactEmail',
    'primaryContactPhone',
    'primaryContactTitle',
  ];
  if (contactFormFields.some((field) => values[field])) {
    return [
      {
        email: values.primaryContactEmail,
        firstName: values.primaryContactFirstName,
        lastName: values.primaryContactLastName,
        phone: values.primaryContactPhone
          ? `+${values.primaryContactPhone}`
          : null,
        businessTitle: values.primaryContactTitle,
      },
    ];
  } else {
    return null;
  }
};
export const getAddress = (values: CreateAceOpportunityFormValues) => {
  const address = {
    city: values.city,
    countryCode: values.countryCode,
    postalCode: values.postalCode,
    stateOrRegion: values.state,
    streetAddress: values.address,
  };
  return address;
};

export const convertAceFormValuesToCreateRequest = (
  values: CreateAceOpportunityFormValues,
): CreateAceOpportunityRequest => {
  return {
    accessControls: getAccessControl(values),
    customer: getCustomer(values),
    lifeCycle: getLifecycle(values),
    marketing: getMarketing(values),
    opportunityType: values.opportunityType,
    partnerOpportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
  };
};
