import { useSalesforceCoSellWidgetButtonGroupStyles } from './SalesforceCoSellWidgetButtonGroup.styles';
import { Button, Grid } from 'vendor/material';
import { useCanvasSession } from 'packages/salesforce-canvas/src/useCanvasSession';
import { useMemo } from 'react';
import { COSELL_PATH } from 'packages/cosell/src/utilities/constants';

/**
 * TODO: Set domain dynamically
 * - can this be added to the canvasSession so it we dont have to use an env var?
 *  */
const downstreamDomain = 'https://localhost:3000';

/** WIP
 *
 * TODO:
 *  - style buttons to match design (color, spacing, layout)
 *  - implement button actions
 *  - add memoized func to generate button actions (will be driven by many factors)
 *  - cleanup code
 */
export const SalesforceCoSellWidgetButtonGroup = ({
  tackleCoSellId,
}: {
  tackleCoSellId?: string;
}) => {
  const { canvasClient, canvasSession, crmId } = useCanvasSession();
  const classes = useSalesforceCoSellWidgetButtonGroupStyles();

  /**
   * WIP
   * TODO:
   * - do we need to manage disabled state?
   */
  const actions = {
    'view-cosell': {
      disabled: false,
      href: `${downstreamDomain}${COSELL_PATH}/opportunity/aws/${tackleCoSellId}`,
      label: 'View Co-Sell',
    },
    'submit-cosell-to-cloud': {
      disabled: false,
      href: null,
      label: 'Submit to Cloud',
    },
    'create-cosell-aws': {
      disabled: false,
      // WIP: this currently points to the salesforce instance domain,
      // but the way it is handled will likely change.
      href: `${canvasClient.instanceUrl}/apex/Create_A_Co_Sell_From_Opportunity?opportunityId=${crmId}`,
      // href: null,
      label: 'Co-Sell with AWS',
    },
    'create-cosell-microsoft': {
      disabled: true,
      // WIP:
      // href: `${canvasClient.instanceUrl}/apex/View_Co_Sell_Details_From_Opportunity?opportunityId=${crmId}`,
      href: null,
      label: 'Co-Sell with Microsoft',
    },
  } as const;

  type ActionId = keyof typeof actions;

  /**
   * WIP
   * this will be driven by many factors, so I might change how it is implemented.
   * factors:
   *  - do any co-sells exist?
   *  - can a co-sell be submitted to cloud?
   *  - can a co-sell be created with AWS?
   *  - can a co-sell be created with Microsoft?
   *  - can a co-sell be viewed?
   *  - can a co-sell be edited?
   */
  const actionsToRender = useMemo(() => {
    const hasCoSell = !!tackleCoSellId;
    const actionSet = new Set<ActionId>();

    switch (true) {
      // no co-sell
      case !hasCoSell:
        actionSet.add('create-cosell-aws');
        actionSet.add('create-cosell-microsoft');
        break;
      // hasCoSell
      case hasCoSell:
        actionSet.add('view-cosell');
        // TODO: check for msft co-sell
        actionSet.add('create-cosell-microsoft');
        // TODO: check if co-sell can be submitted to cloud
        actionSet.add('submit-cosell-to-cloud');
        break;
      default:
        break;
    }

    return Array.from(actionSet);
  }, [tackleCoSellId]);

  const handleActionClick = (action: ActionId) => () => {
    /** WIP:
     *
     * if the action is not a link,
     * we need to handle here in a switch statement.
     */
    alert(`TODO: handle action: ${action}`);
  };

  console.log('canvasSession', canvasSession);

  return (
    <div className={classes.buttonGroupContainer}>
      {actionsToRender.map((action) => (
        <Grid key={action} item>
          <Button
            data-testid={action}
            disabled={actions[action].disabled}
            className={classes.buttonElement}
            variant="outlined"
            color="primary"
            href={actions[action].href}
            onClick={
              !actions[action].href ? handleActionClick(action) : undefined
            }
            target="_blank"
          >
            {actions[action].label}
          </Button>
        </Grid>
      ))}

      {/* {tackleCoSellId && (
        
        <Button
          className={classes.dashboardButtonElement}
          variant="contained"
          color="primary"
          size="large"
          href={`https://localhost:3000/${COSELL_PATH}/opportunity/aws/${tackleCoSellId}`}
          target="_blank"
        >
          View Co-Sell Opportunity
        </Button>
      )} */}
      <Grid container className={classes.bottomButtonContainer}>
        {/* {
          tackleCoSellId && (
            <Grid item xs={12}>
              <Button
                className={classes.buttonElement}
                variant="outlined"
                color="primary"
                href="https://downstream.tackle.io/co-sell"
                target="_blank"
              >
                Dashboard
              </Button>
            </Grid>
          )
        } */}
      </Grid>
    </div>
  );
};
