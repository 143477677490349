import { AceSolutionResponse } from '../types/responses/AceSolutionResponse';

export const solutionsApiToFormFormatter = (
  solutions: AceSolutionResponse[],
) => {
  return solutions?.map((s) => ({
    display: `${s.name}: ${s.cloudProviderId}`,
    value: s.cloudProviderId,
  }));
};
