import ky from 'ky';

/**
 * NOTE: Temporary for development
 * this was created to work with the api without conflicts.
 * Once co-sell api is integrated, this will be removed or refactored.
 */

let config = {
  prefixUrl: process.env.REACT_APP_TACKLE_CO_SELL_V3_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      async (request: Request) => {
        const bearerToken = Sfdc.canvas.oauth.token();
        if (bearerToken) {
          request.headers.set('Authorization', `Bearer ${bearerToken}`);
        }
      },
    ],
  },
};

let cosellHttpClient = ky.extend(config);

export { cosellHttpClient };
