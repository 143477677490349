// TODO: Remove /v3/ route when done testing & v3 is Live & use FeatureFlag
export const COSELL_PATH = '/co-sell/v3';
const LANDING_PAGE_URL = '/co-sell/v3';
/**
 * SINGLE_OPPORTUNITY_URL for create/update/details page of a single opportunity
 */
const SINGLE_OPPORTUNITY_URL = `${COSELL_PATH}/opportunity`;
const EDIT_PATH_URL = '/edit';

export enum UnifiedOpportunityView {
  CO_SELL_DETAILS = 'CoSellDetails',
  CREATE_NEW_CO_SELL_FORM = 'CreateNewCoSellForm',
  EDIT_CO_SELL_FORM = 'EditCoSellForm',
  CO_SELL_LANDING_PAGE = 'CoSellLandingPage',
}

export const getUnifiedOpportunityView = ({
  url,
  opportunityId,
}: {
  url: string;
  opportunityId?: string;
}): UnifiedOpportunityView => {
  const isEdit = url.endsWith(EDIT_PATH_URL);
  if (url === LANDING_PAGE_URL) {
    return UnifiedOpportunityView.CO_SELL_LANDING_PAGE;
  } else if (!opportunityId && url.includes(SINGLE_OPPORTUNITY_URL)) {
    return UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM;
  } else if (opportunityId && isEdit) {
    return UnifiedOpportunityView.EDIT_CO_SELL_FORM;
  } else if (opportunityId && !isEdit) {
    return UnifiedOpportunityView.CO_SELL_DETAILS;
  }
  return null;
};
