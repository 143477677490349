export const getTackleSessionFromOtk = async (otk: string) => {
  const otkRequest = await fetch(
    'https://auth-service.dev.tkl.sh/api/v1/auth/one-time-keys/exchange',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: otk,
      }),
    },
  );

  const otkRequestJson =
    (await otkRequest.json()) as IOneTimeKeyExchangeResponse;

  return otkRequestJson;
};

export interface IOneTimeKeyExchangeResponse {
  access_token: string;
  expires_in_seconds: number;
  context: SalesforceCanvasContext;
}

interface SalesforceCanvasContext {
  action: string;
  client: Client;
  record: {
    Id: string;
    attributes: {
      type: 'Opportunity';
      url: string;
    };
  };
  dimensions: Dimensions;
  parameters: Parameters;
}

interface Client {
  instanceId: string;
  oauthToken: string;
  instanceUrl: string;
  refreshToken: any;
  targetOrigin: string;
}

interface Dimensions {
  width: string;
  height: string;
  maxWidth: string;
  maxHeight: string;
  clientWidth: string;
  clientHeight: string;
}

interface Parameters {
  token?: string;
  context?: string;
  clientId?: string;
  vendorId?: string;
  clientSecret?: string;
  selectedOpportunities?: SelectedOpportunity[];
}

interface SelectedOpportunity {
  Id: string;
  attributes: Attributes;
}

interface Attributes {
  url: string;
  type: string;
}
