import useStyles from './CoSellTabs.styles';
import { Box } from 'vendor/material';

export const CoSellTabs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.tabsContainer} sx={{ display: 'flex' }} mb={1}>
      <h3>Opportunities / Invitations Tabs</h3>
    </Box>
  );
};
