import React from 'react';
import { Box, Grid, Typography } from 'vendor/material';
import { ProviderIcon } from '@tackle-io/platform-ui';
// import { calculateDaysBetweenDateAndToday } from 'utils/dates';
import useStyles from './AceOpportunityDetailsHeader.styles';

import {
  DisplayCloudType,
  AceOpportunityReviewStatusEnum,
  TagColorEnum,
  AceOpportunityStageEnum,
} from 'packages/cosell/src/types/enums';
import Tag from 'packages/cosell/src/components/Tag/Tag';
import { LifeCycle } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

const DRAFT = 'Draft';
interface AceOpportunityDetailsHeaderProps {
  origin: string;
  title: string;
  lifeCycle: LifeCycle;
}

export const getStatusTagColor = (status: AceOpportunityReviewStatusEnum) => {
  if (status === AceOpportunityReviewStatusEnum.ACTION_REQUIRED) {
    return TagColorEnum.YELLOW;
  } else if (status === AceOpportunityReviewStatusEnum.REJECTED) {
    return TagColorEnum.RED;
  } else {
    return TagColorEnum.STANDARD;
  }
};

export const getStageTagColor = (stage: AceOpportunityStageEnum) => {
  if (stage === AceOpportunityStageEnum.LAUNCHED) {
    return TagColorEnum.GREEN;
  } else if (stage === AceOpportunityStageEnum.CLOSED_LOST) {
    return TagColorEnum.RED;
  } else {
    return TagColorEnum.STANDARD;
  }
};

const AceOpportunityDetailsHeader: React.FC<
  AceOpportunityDetailsHeaderProps
> = ({ origin, title, lifeCycle }) => {
  const classes = useStyles();
  const renderStatus = (status: AceOpportunityReviewStatusEnum) => {
    if (status === AceOpportunityReviewStatusEnum.PENDING_SUBMISSION) {
      return DRAFT; //TODO: check to see if we have internal review status
    } else {
      return status;
    }
  };

  return (
    <>
      <Box className={classes.nameContainer} sx={{ display: 'flex' }} mb={1}>
        <Box sx={{ display: 'flex' }} mr={1}>
          <ProviderIcon fontSize={'large'} provider={DisplayCloudType.AWS} />
        </Box>

        {title && (
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
          </Box>
        )}
      </Box>

      <Grid container spacing={1}>
        <Grid item>
          <Tag color={TagColorEnum.GREEN}>v14</Tag>
        </Grid>
        {origin && (
          <Grid item>
            <Tag>{origin}</Tag>
          </Grid>
        )}
        {lifeCycle?.reviewStatus && (
          <Grid item>
            <Tag color={getStatusTagColor(lifeCycle?.reviewStatus)}>
              {renderStatus(lifeCycle?.reviewStatus)}
            </Tag>
          </Grid>
        )}
        {lifeCycle?.stage && (
          <Grid item>
            <Tag color={getStageTagColor(lifeCycle?.stage)}>
              {lifeCycle?.stage}
            </Tag>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AceOpportunityDetailsHeader;
