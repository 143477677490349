import { UnifiedOpportunityFormShell } from '../UnifiedOpportunityFormShell';
import { DisplayCloudType } from 'packages/cosell/src/types/enums';
import { ampli } from 'utils/analytics/ampli';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import coSellClient from 'packages/cosell/api/coSellClient';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import { convertOpportunityResponseToAceFormValues } from 'packages/cosell/src/utilities/typeConverters/convertOpportunityResponseToAceFormValues';
import { isEmpty } from 'lodash-es';
import {
  convertAceFormValuesToUpdateRequest,
  getSoftwareRevenue,
} from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToUpdateRequest';
import {
  getEditPageSubmitTackleOperationId,
  TackleOperationId,
} from 'packages/cosell/api/utils';
import { getMockInitialValues } from 'packages/cosell/api/mockHelpers';

interface AceOpportunityFormProps {
  opportunityId?: string;
}
const AceOpportunityForm = ({ opportunityId }: AceOpportunityFormProps) => {
  const { token, opportunity, isSRRP } = useOpportunity();
  const isEdit = !!opportunityId;

  const authorizedCosellClient = coSellClient(token);
  const mockInitialValues = getMockInitialValues();

  const handleCreateOrUpdateOpportunity = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    if (isEdit) {
      const body = convertAceFormValuesToUpdateRequest(values);
      if (isSRRP) {
        const softwareRevenue = getSoftwareRevenue(values);
        body.softwareRevenue = softwareRevenue;
      }
      const tackleOperationId = getEditPageSubmitTackleOperationId(
        opportunity?.lifeCycle?.reviewStatus,
      );
      const response = await authorizedCosellClient.updateOpportunity(
        body,
        opportunityId,
        tackleOperationId,
      );
      return response;
    } else {
      const requestBody = convertAceFormValuesToCreateRequest(values);
      if (isSRRP) {
        const softwareRevenue = getSoftwareRevenue(values);
        requestBody.softwareRevenue = softwareRevenue;
      }
      const response = await authorizedCosellClient.createOpportunity(
        requestBody,
        TackleOperationId.CREATE_OPPORTUNITY,
      );

      return response;
    }
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    try {
      const submittedOpportunity = await handleCreateOrUpdateOpportunity(
        values,
      );
      ampli.outboundSubmitted({ cloud: 'aws' });
      return submittedOpportunity;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    return await authorizedCosellClient.createOpportunity(
      requestBody,
      TackleOperationId.CREATE_DRAFT_OPPORTUNITY,
    );
  };
  const flattenedAceFormValues =
    !isEmpty(opportunity) &&
    convertOpportunityResponseToAceFormValues(opportunity);
  return (
    <UnifiedOpportunityFormShell
      opportunityId={opportunityId}
      opportunity={opportunity}
      onSubmit={handleSubmitAceOpportunityToCloud}
      onSaveAsDraft={handleSaveAceOpportunityAsDraft}
      initialValues={
        flattenedAceFormValues ? flattenedAceFormValues : mockInitialValues
      }
      provider={DisplayCloudType.AWS}
      validationSchema={aceOpportunityFormValidationSchema}
    >
      <AceOpportunityFormFields />
    </UnifiedOpportunityFormShell>
  );
};

export default AceOpportunityForm;
