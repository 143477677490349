import React from 'react';
import { Tag } from '../Tag';
import { TagColorEnum } from '../../types/enums';

export enum CoSellSyncStatus {
  SYNCED = 'Synced',
  SYNCING = 'Syncing',
  SYNC_ERROR = 'Sync Error',
  SALES_STAGE_OUT_OF_SYNC = 'Sales Stage out of sync',
}

export const getSyncStatus = (pendingRequestIds: string[] | null) => {
  if (pendingRequestIds?.length) {
    return CoSellSyncStatus.SALES_STAGE_OUT_OF_SYNC;
  } else if (pendingRequestIds === null) {
    return CoSellSyncStatus.SYNCED;
  }
  return null;
};

const statusToColorMapping: { [key in CoSellSyncStatus]: TagColorEnum } = {
  [CoSellSyncStatus.SYNCED]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNCING]: TagColorEnum.STANDARD,
  [CoSellSyncStatus.SYNC_ERROR]: TagColorEnum.RED,
  [CoSellSyncStatus.SALES_STAGE_OUT_OF_SYNC]: TagColorEnum.YELLOW,
};

interface CoSellSyncStatusTagProps {
  status: CoSellSyncStatus;
}

export const CoSellSyncStatusTag = (props: CoSellSyncStatusTagProps) => {
  return <Tag color={statusToColorMapping[props.status]}>{props.status}</Tag>;
};
